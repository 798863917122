/** @format */

import LeaderPicture from './LeaderPicture';
import { Score } from './Ranking';

type Props = {
  player: Score;
  realIndex: number;
  userIndex: number;
};

const Rank = ({ player, realIndex, userIndex }: Props) => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        width: '95%',
        alignItems: 'center',
      }}
    >
      <div style={{ width: '15%' }}>
        <p>{realIndex}</p>
      </div>
      <div
        style={
          userIndex === realIndex
            ? {
                backgroundColor: 'white',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                padding: 10,
                borderRadius: 11,
                marginTop: 10,
                marginBottom: 10,
                width: '85%',
                border: '5px solid #B031D6',
              }
            : {
                backgroundColor: 'white',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                padding: 10,
                borderRadius: 11,
                marginTop: 10,
                marginBottom: 10,
                width: '85%',
              }
        }
      >
        <div style={{ justifyContent: 'center', alignItems: 'flex-start' }}>
          <LeaderPicture photoStorageLink={player.photoStorageLink} isFirst={false} />
        </div>
        <div style={{ left: 0, width: '70%', flexDirection: 'column' }}>
          <span
            style={{
              fontFamily: 'Earth orbiter',
              display: 'flex',
              fontSize: 20,
              color: '#333333',
              overflow: 'hidden',
            }}
          >
            {player.userName === 'atlantideAgent' ? 'Tisseur EVA' : player.userName}
          </span>
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <div
              style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', paddingTop: 7, paddingBottom: 7 }}
            >
              <img src={require('../../assets/img/goldBadge.png')} style={{ height: 20, width: 20 }} />
              <span
                style={{
                  fontFamily: 'Nasalization',
                  fontSize: 12,
                  color: '#333333',
                  marginLeft: 5,
                }}
              >
                <span style={{ fontWeight: '500', fontSize: 12 }}>{`${player.score}`}</span> points
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Rank;
