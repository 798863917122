/** @format */

interface Routes {
  login: string;
  landing: string;
}

export const routes: Routes = {
  login: '/login',
  landing: '/',
};
