/** @format */

import React from 'react';
import { UserData } from '../../pages/Landing/LandingScreen';

type Props = {
  userData: UserData;
  updateUserName: (userName: string) => void;
};

const MyProfile = ({ userData, updateUserName }: Props) => {
  const [userName, setUserName] = React.useState<string>('');
  const [collarNumber, setCollarNumber] = React.useState<string>('');

  React.useEffect(() => {
    if (userData) {
      setUserName(userData.userName);
      setCollarNumber(userData.collarNumber);
    }
  }, [userData]);

  return (
    <>
      <h1 style={{ textTransform: 'uppercase' }}>Mon profil</h1>
      <p>Pseudo</p>
      <input
        type="text"
        name="userName"
        id="userName"
        value={userName}
        onChange={(e) => setUserName(e.target.value)}
        className="form-input user-info-input username-input"
        style={{ textAlign: 'center' }}
      />
      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
        <p
          className={userName !== userData.userName ? 'text-button cursor-pointer' : 'text-button'}
          style={{ marginRight: '20px', color: userName !== userData.userName ? '#9A1212' : '#9A121250' }}
          onClick={userName !== userData.userName ? () => setUserName(userData.userName) : (e) => e.preventDefault()}
        >
          Annuler
        </p>
        <p
          className={userName !== userData.userName ? 'text-button cursor-pointer' : 'text-button'}
          style={{ marginLeft: '20px', color: userName !== userData.userName ? '#53BCF2' : '#53BCF250' }}
          onClick={userName !== userData.userName ? () => updateUserName(userName) : (e) => e.preventDefault()}
        >
          Sauvegarder
        </p>
      </div>
      <p>Matricule</p>
      <div className="form-input user-info-input collar-number" style={{ textAlign: 'center' }}>
        #{collarNumber}
      </div>
    </>
  );
};

export default MyProfile;
