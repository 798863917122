/** @format */

import LeaderPicture from './LeaderPicture';
import { Score } from './Ranking';
import { isMobile } from 'react-device-detect';

type Props = {
  leaders: Array<Score>;
};

const LeaderBoard = ({ leaders }: Props) => {
  const renderLeader = (leader: Score, position: number, text: string) => {
    return (
      <div
        style={
          position === 1
            ? { marginLeft: 10, marginRight: 10, alignItems: 'center' }
            : { marginLeft: 10, marginRight: 10, marginTop: 75, alignItems: 'center' }
        }
      >
        <div
          style={{
            textAlign: 'center',
            fontFamily: 'Earth orbiter',
            marginBottom: 10,
            color: 'white',
            fontSize: '20px',
          }}
        >
          <span style={{ fontSize: '35px' }}>{position}</span>
          {text}
        </div>
        <LeaderPicture
          photoStorageLink={leader.photoStorageLink}
          size={position === 1 ? (isMobile ? 100 : 150) : isMobile ? 75 : 125}
          isFirst={position === 1}
        />
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            textAlign: 'center',
          }}
        >
          <p
            style={{
              marginTop: 20,
              color: 'white',
              width: position === 1 ? (isMobile ? 100 : 150) : isMobile ? 75 : 125,
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
              fontFamily: 'Nasalization',
              fontSize: 15,
            }}
          >
            {leader.userName === 'atlantideAgent' ? 'Tisseur EVA' : leader.userName}
          </p>
        </div>
      </div>
    );
  };
  return (
    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', marginTop: '5vh' }}>
      {leaders.length >= 2 && renderLeader(leaders[1], 2, 'nd')}
      {leaders.length >= 1 && renderLeader(leaders[0], 1, 'st')}
      {leaders.length >= 3 && renderLeader(leaders[2], 3, 'rd')}
    </div>
  );
};

export default LeaderBoard;
