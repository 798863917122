/** @format */

import React, { useContext } from 'react';
import { AuthContext } from '../../contexts/AuthContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { db } from '../../services/Firebase';
import MyProfile from '../../components/MyProfile';
import { Ranking } from '../../components/Ranking';
import Lottie from 'lottie-react';

export type UserData = {
  collarNumber: string;
  uid: string;
  userName: string;
};

const LandingScreen = () => {
  const [userData, setUserData] = React.useState<UserData | null>(null);
  const { currentUser, logout } = useContext(AuthContext);

  const getUserData = async (uid: string) => {
    const result = await db.ref(`users/${uid}`).once('value');
    if (result.exists()) {
      const resultValue = result.val();
      if (resultValue) {
        setUserData(resultValue);
      }
    }
  };

  const changeUserName = async (userName: string) => {
    try {
      if (userData) {
        await db.ref(`users/${userData.uid}`).child('userName').set(userName);
        getUserData(userData.uid);
      }
    } catch (err) {
      console.error(err);
    }
  };

  React.useEffect(() => {
    if (currentUser) {
      const userUid = currentUser._delegate.uid;
      getUserData(userUid);
    }
  }, [currentUser]);

  return (
    <div>
      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
        <a className="gradient-button button-icon" href="https://mission-eva.yvelines.fr">
          <span style={{ paddingRight: '10px', paddingTop: '2px' }}>
            <FontAwesomeIcon icon={solid('arrow-left-long')} />
          </span>
          <span>Retour</span>
        </a>
        <button className="gradient-button" onClick={() => logout()}>
          Déconnexion
        </button>
      </div>
      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
        <div style={{ width: '60%', marginTop: '20vh' }}>
          {userData ? (
            <>
              <MyProfile userData={userData} updateUserName={changeUserName} />
              <div className="big-spacer"></div>
              <Ranking userData={userData} />
            </>
          ) : (
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <div style={{ height: '400px', width: '400px' }}>
                <Lottie animationData={require('../../assets/lottie/loader.json')} />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default LandingScreen;
