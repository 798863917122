/** @format */

import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { AuthContext } from '../../contexts/AuthContext';
import { routes } from '../../constants/routes';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { isMobile } from 'react-device-detect';

const LoginScreen = () => {
  const [userEmail, setUserEmail] = React.useState<string>('');
  const [userPassword, setUserPassword] = React.useState<string>('');
  const [formPending, setFormPending] = React.useState<boolean>(false);
  const [error, setError] = React.useState<string>('');

  const { currentUser, login } = useContext(AuthContext);
  const history = useHistory();

  React.useEffect(() => {
    if (currentUser) {
      history.push(routes.landing);
    }
  }, []);

  const onLogin = async () => {
    try {
      setFormPending(true);
      await login(userEmail, userPassword);
      history.push(routes.landing);
    } catch {
      setError('Connexion impossible');
    }
    setFormPending(false);
  };

  return (
    <div>
      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
        <a className="gradient-button button-icon" href="https://mission-eva.yvelines.fr">
          <span style={{ paddingRight: '10px', paddingTop: '2px' }}>
            <FontAwesomeIcon icon={solid('arrow-left-long')} />
          </span>
          <span>Retour</span>
        </a>
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          marginTop: '5vh',
        }}
      >
        <img
          src={require('../../assets/img/eva-intro.png')}
          style={{
            height: 300,
          }}
        />
        <div
          style={{
            backgroundColor: '#ffffff',
            width: isMobile ? '80vw' : '400px',
            paddingTop: '3vh',
            paddingBottom: '3vh',
            marginTop: '-8vh',
          }}
        >
          <h1 style={{ color: 'black', textAlign: 'center', fontFamily: 'Earth Orbiter' }}>Connexion</h1>
          <form method="get" className="form-example" style={{ marginLeft: '10%', marginRight: '10%' }}>
            <div className="form-example" style={{ marginTop: '2vh', marginBottom: '2vh' }}>
              <input
                type="email"
                name="email"
                id="email"
                value={userEmail}
                onChange={(e) => setUserEmail(e.target.value)}
                placeholder="Adresse e-mail"
                className="form-input"
                required
              />
            </div>
            <div className="form-example" style={{ marginTop: '2vh', marginBottom: '2vh' }}>
              <input
                type="password"
                name="password"
                id="password"
                value={userPassword}
                onChange={(e) => setUserPassword(e.target.value)}
                placeholder="Mot de passe"
                className="form-input"
                required
              />
            </div>
            {error && <p style={{ color: 'red' }}>{error}</p>}
            <div className="form-example">
              <button
                className="gradient-button"
                onClick={() => onLogin()}
                style={{ width: '100%' }}
                disabled={formPending}
              >
                Se connecter
              </button>
            </div>
          </form>
        </div>
      </div>
      <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '10vh'}}>
        <img
          src={require('../../assets/img/logo_yvelines_negatif.png')}
          style={{
            height: 70,
            width: "auto",
          }}
         alt=""/>
      </div>
    </div>
  );
};

export default LoginScreen;
