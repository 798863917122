/** @format */

import React, { useContext } from 'react';
import { Redirect, Route, RouteProps } from 'react-router';
import { routes } from '../../constants/routes';
import { AuthContext } from '../../contexts/AuthContext';

interface Props extends RouteProps {
  component: any;
}

const ProtectedRoute: React.FC<Props> = ({ component: Component }) => {
  const { currentUser } = useContext(AuthContext);
  return (
    <Route
      render={(props) => {
        return currentUser ? <Component {...props} /> : <Redirect to={routes.login} />;
      }}
    />
  );
};

export default ProtectedRoute;
