/** @format */

import firebase from 'firebase/compat/app';
import React from 'react';
import { auth } from '../services/Firebase';

export interface AuthContext {
  currentUser: any;
  login: (email: string, password: string) => Promise<firebase.auth.UserCredential>;
  logout: () => Promise<void>;
}

interface AuthProviderProps {
  children: React.ReactNode;
}
const initialContext = {
  currentUser: null,
  login: (email: string, password: string) => auth.signInWithEmailAndPassword(email, password),
  logout: () => auth.signOut(),
};

export const AuthContext = React.createContext<AuthContext>(initialContext);

export const AuthProvider = ({ children }: AuthProviderProps) => {
  const [currentUser, setCurrentUser] = React.useState<any>();
  const [loading, setLoading] = React.useState<boolean>(true);

  React.useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user: any) => {
      setCurrentUser(user);
      setLoading(false);
    });

    return unsubscribe;
  }, []);

  const value: AuthContext = {
    ...initialContext,
    currentUser,
  };

  return <AuthContext.Provider value={value}>{!loading && children}</AuthContext.Provider>;
};
