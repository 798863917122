/** @format */

import React from 'react';
import { Route, Switch } from 'react-router-dom';
import ProtectedRoute from './components/ProtectedRoute';
import { routes } from './constants/routes';
import { AuthContext, AuthProvider } from './contexts/AuthContext';
import LandingScreen from './pages/Landing';
import LoginScreen from './pages/Login';

const App = () => {
  const { currentUser, logout } = React.useContext(AuthContext);

  return (
    <AuthProvider>
      <div className="radial-gradient"></div>
      <Switch>
        <Route exact path={routes.login} component={LoginScreen} />
        <ProtectedRoute exact path={routes.landing} component={LandingScreen} />
      </Switch>
    </AuthProvider>
  );
};

export default App;
