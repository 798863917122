/** @format */

import React from 'react';
import { functions } from '../../services/Firebase';
import Pagination from '../Pagination';
import LeaderBoard from './LeaderBoard';
import Rank from './Rank';
import { isMobile } from 'react-device-detect';
import { UserData } from '../../pages/Landing/LandingScreen';
import firebase from 'firebase/compat';

export type Score = {
  score: number;
  userName: string;
  collarNumber: string;
  userId: string;
  photoStorageLink: string | null;
  position: number;
};

type Props = {
  userData: UserData;
};

type Ranking = {
  ranking: Array<Score>;
  userPosition: number;
};

const Ranking = ({ userData }: Props) => {
  const [currentPage, setCurrentPage] = React.useState<number>(1);
  const [sortedScores, setSortedScores] = React.useState<Array<Score>>([]);
  const [allScores, setAllScores] = React.useState<Array<Score>>([]);

  const getAllScores = async () => {
    const result: firebase.functions.HttpsCallableResult = await functions.httpsCallable('getRanking')({});
    if (result.data) {
      const { ranking } = result.data;
      setAllScores(ranking);
    }
  };

  React.useEffect(() => {
    getAllScores();
  }, []);

  React.useEffect(() => {
    if (allScores.length) {
      const firstPageIndex = (currentPage - 1) * 16;
      const lastPageIndex = firstPageIndex + 16;
      const allScoresList = allScores;
      const newSortedScores: Array<Score> = Object.values(allScoresList)
        .sort((a, b) => b.score - a.score)
        .slice(firstPageIndex, lastPageIndex);
      setSortedScores(newSortedScores);
    }
  }, [allScores, currentPage]);

  return (
    <div style={{ marginBottom: '10vh' }}>
      <h1 style={{ textTransform: 'uppercase' }}>Classement</h1>
      <LeaderBoard leaders={allScores.sort((a, b) => b.score - a.score).slice(0, 3)} />
      <div
        style={{
          marginTop: '10vh',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
        }}
      >
        <div
          style={{
            minWidth: isMobile ? '80vw' : '400px',
            maxWidth: isMobile ? '80vw' : '400px',
            marginLeft: isMobile ? 0 : 30,
            marginRight: isMobile ? 0 : 30,
          }}
        >
          {sortedScores.slice(0, !isMobile ? 8 : 16).map((player: Score, index: number) => {
            const realIndex = allScores.findIndex((score) => {
              return score.userId === player.userId;
            });
            const userIndex = allScores.findIndex((score) => {
              return score.userId === userData.uid;
            });
            return <Rank player={player} realIndex={realIndex + 1} userIndex={userIndex + 1} key={index} />;
          })}
        </div>
        {!isMobile && (
          <div style={{ width: isMobile ? '80vw' : '400px', marginLeft: 30, marginRight: 30 }}>
            {sortedScores.slice(8, 16).map((player: Score, index: number) => {
              const realIndex = allScores.findIndex((score) => {
                return score.userId === player.userId;
              });
              const userIndex = allScores.findIndex((score) => {
                return score.userId === userData.uid;
              });
              return <Rank player={player} realIndex={realIndex + 1} userIndex={userIndex + 1} key={index} />;
            })}
          </div>
        )}
      </div>
      <div
        style={{
          marginTop: '5vh',
          textAlign: 'center',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Pagination
          siblingCount={1}
          currentPage={currentPage}
          totalCount={allScores.length}
          pageSize={16}
          onPageChange={(page: string | number) => setCurrentPage(Number(page))}
        />
      </div>
    </div>
  );
};

export default Ranking;
