/** @format */

import classnames from 'classnames';
import React from 'react';
import { usePaginationHook, DOTS } from '../../hooks/UsePaginationHook';

type Props = {
  onPageChange: (page: number | string) => void;
  totalCount: number;
  siblingCount: number;
  currentPage: number;
  pageSize: number;
};

const Pagination = ({ onPageChange, totalCount, siblingCount = 1, currentPage, pageSize }: Props) => {
  const paginationRange = usePaginationHook({
    currentPage,
    totalCount,
    siblingCount,
    pageSize,
  });

  if (currentPage === 0 || (paginationRange && paginationRange.length < 2)) {
    return null;
  }

  return (
    <div style={{ display: 'flex', flexDirection: 'row' }}>
      {paginationRange &&
        paginationRange.map((pageNumber: number | string, p: number) => {
          if (pageNumber === DOTS) {
            return (
              <p key={p} className="pagination-item dots">
                &#8230;
              </p>
            );
          }

          return (
            <div className={pageNumber === currentPage ? 'active-page' : 'pagination-item'} key={p}>
              <p
                onClick={() => onPageChange(pageNumber)}
                className="cursor-pointer"
                style={{ marginLeft: '5px', marginRight: '5px' }}
              >
                {pageNumber}
              </p>
            </div>
          );
        })}
    </div>
  );
};

export default Pagination;
